<script>
    import router from '../router.js';
    import Dropdown from '@/components/Dropdown.vue';
    import LanguageSwitcher from './LanguageSwitcher.vue';
    import VueRouter from 'vue-router';
    const { isNavigationFailure, NavigationFailureType } = VueRouter;

    export default {
        name: 'nav-bar',
        props: {
            user: Object,
            // no real navigation is possible, (usually public pages)
            noNav: {
                type: Boolean,
                default: false,
            },
            // you can't even go to the login page
            noLogin: {
                type: Boolean,
                default: false,
            },
            helpUrl: String,
            gdprUrl: String,
        },
        components: {
            'language-switcher': LanguageSwitcher,
            'dropdown': Dropdown,
        },
        data () {
            return {
                active: false,
            };
        },
        methods: {
            logout () {
                this.$store.dispatch('logout');
                router.replace('/default').catch((err) => {
                    // ignore router errors thrown by the redirect from /default
                    if (!isNavigationFailure(err, NavigationFailureType.redirect)) {
                        throw err;
                    }
                });
            },
            toggleMenu () {
                this.active = !this.active;
            },
            goToHomePage () {
                if (this.noLogin) return;
                this.$router.push('/#/default/');
            },
        },
    };
</script>

<template>
    <nav class='navbar navbar-default navbar-fixed-top'
         role='navigation'
    >
        <div class='navbar-header'>
            <button type='button'
                    class='navbar-toggle'
                    @click='toggleMenu'
            >
                <span class='sr-only'>Toggle Navigation</span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
            </button>
            <div class='h-14 ml-6 mr-32 md:mr-0 flex items-center'>
                <div
                    class='navbar__logo'
                    :class='{"clickable": !noLogin}'
                    @click.prevent='goToHomePage'
                >
                    <img src='~@/assets/mycodabox-logo-white-small.svg' alt='Codabox logo'>
                </div>
                <div
                    class='navbar__logo__full'
                    :class='{"clickable": !noLogin}'
                    @click.prevent='goToHomePage'
                >
                    <img src='~@/assets/mycodabox-logo-white.svg' alt='Codabox logo'>
                </div>
                <slot name='platform' v-if='!noLogin'></slot>
            </div>
        </div>

        <div class='navbar__sub' :class='{active}'>
            <ul class='nav navbar-nav pull-left ml-3'>
                <li v-if='gdprUrl'>
                    <a target='blank' :href='gdprUrl'>
                        <i class='fa fa-info-circle'></i>
                        <span class='cb-nav-item navbar__rgpd'>{{ $t('nav-gdpr') }}</span>
                    </a>
                </li>
                <li v-if='helpUrl'>
                    <a target='blank' :href='helpUrl'>
                        <i class='fa fa-info-circle'></i>
                        <span class='cb-nav-item navbar__help'>{{ $t('nav-fidu-help') }}</span>
                    </a>
                </li>
                <li v-if='user && ($store && $store.state.flags.mvp)'>
                    <a href='#/organization/settings' :class='{"nav-active": $route.meta.category === "organization-settings"}'>
                        <i class='fa fa-cog'></i>
                        <span class='cb-nav-item'>{{ $t('nav-org-settings') }}</span>
                    </a>
                </li>
                <dropdown tag='li' v-if='user && user.username'>
                    <template slot='toggle'>
                        <i class='fa fa-user'></i>
                        <span class='cb-nav-item flex'>
                            {{ user.username }}
                            <span class='cb-user-email max-w-xs truncate inline-block' v-if='user.email'>
                                ( {{ user.email }} )
                            </span>
                        </span>
                        <i class='fa fa-chevron-down'></i>
                    </template>
                    <template slot='list' v-if='!noNav'>
                        <router-link to='/change-password' tag='li'>
                            <a>{{ $t('nav-change-password') }}</a>
                        </router-link>
                        <li><a class='cb-e2e-logout' @click.prevent='logout()' href='#'>{{ $t('nav-logout') }}</a></li>
                    </template>
                </dropdown>
                <language-switcher />
            </ul>
        </div>
    </nav>
</template>

<style lang='scss' scoped>
/* ------------------ *\
  |*     TOP NAVBAR     *|
  \*--------------------*/

.clickable {
    cursor: pointer;
}

.navbar-nav li.loader {
  margin: 12px 24px 0 6px;
  color: white;
  animation-name: showup;
  animation-duration: 500ms;
}

.navbar__logo, .navbar__logo__full {
    margin-right: 22px;
}

.navbar__logo img {
    width: 20px;
}

.navbar__logo__full img {
    width: 140px;
}
.navbar__logo__full {
    display: none;
}

@screen md {
    .navbar__logo {
        display: none;
    }

    .navbar__logo__full {
        display: inline-block;
    }
}

.cb-navbar-brand-separator {
  color: #95d3ed;
}
.cb-navbar-brand-name {
  font-weight: 300;
}
.cb-navbar-api {
  font-style: italic;
  font-weight: 200;
  margin-left: -2px !important;
}
.pictureDetail {
  max-width: 425px;
}

body .navbar-default .navbar-toggle {
  border: none;
}
body .navbar-default .navbar-toggle:hover,
body .navbar-default .navbar-toggle:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.cb-nav-item {
  margin-left: 10px;
  margin-right: 10px;
}
.cb-user-email {
  font-weight: 200;
  font-style: italic;
  margin-left: 5px;
}

.navbar__sub {
    display: none;
}

.navbar__sub.active {
    display: block;
}

.navbar-header {
    width: 100%;
}

.nav-active {
    font-weight: 800;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: block;
  }
}

@media (min-width: 1250px) {
  .navbar__sub {
    display: block;
    float: right;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-header {
    width: auto;
  }
}

/* scroll in absolute doesn't work reliably on mobile ... remove all fixed & absolutes */
@media (max-width: 767px) {
  .navbar.navbar-fixed-top {
    display: block;
    z-index: 100;
    margin-bottom: 0;
  }

  .navbar-brand {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
