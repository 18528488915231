<script>
import { gql } from '@apollo/client/core';
import Loader from '@/loader';
import validate from '@/validate';
import notify from '@/notify';
import Modal from '@/components/Modal';
import FormField from '@/components/FormField';
import PhoneField from '@/components/PhoneField';
import ContentBox from '@/components/ContentBox';

const EDIT = 'edit';
const NEW = 'new';

const activationErrorsMap = {
    'ALREADY_ACTIVE': 'err-ccs-already-active',
    'INVALID': 'err-css-invalid-json',
    'INVALID_SIZE': 'err-css-invalid-json',
    'INVALID_MISSING': 'err-css-invalid-json',
    'INVALID_PATTERN': 'err-css-invalid-json',
    'INVALID_UUID': 'err-css-invalid-json',
    'INVALID_EMAIL': 'err-css-invalid-json',
    'INVALID_VALUE': 'err-css-invalid-json',
    'INVALID_LUHN_CHECK': 'err-css-invalid-json',
    'INVALID_HANDLER': 'err-server-fail',
    'INVALID_JSON': 'err-server-fail',
};

export default {
    name: 'client-caro',
    props: {
        clientV2: { type: Object, required: true },
        accountant: {type: Object, required: true},
    },
    components: {
        Modal,
        FormField,
        PhoneField,
        ContentBox,
    },
    data () {
        return {
            saving: false,
            loading: false,
            showActivationModal: false,
            activation: {},
            activationType: null,
            registrations: [],
            codaMandates: [],
            downloadDate: {
                year: null,
                month: null,
            },
            displayDownloadPopup: false,
            showDeactivationModal: false,
            registrationToDeactivate: null,
            deactivationPayload: {
                reason: '',
                freeNote: '',
            },

            // otherwise it's no available in template
            NEW: NEW,
            EDIT: EDIT,
        };
    },
    computed: {
        hasSubscribedCcs () {
            return this.registrations && this.registrations.length > 0;
        },
        hasNoRegistrationsWithLastReceivedAt () {
            return this.registrations.every((r) => r.lastReceivedAt === null);
        },
        isClientEligible () {
            return this.codaMandates.findIndex(mandate => {
                return mandate.bank.isCaroSupported;
            }) >= 0;
        },
        isClientOrderable () {
            return this.codaMandates.findIndex(mandate => {
                return mandate.state === 'active' && mandate.bank.isCaroSupported;
            }) >= 0;
        },
        downloadYears () {
            const now = new Date();
            const years = [];

            for (let index = 0; index < 3; index++) {
                years.push((now.getFullYear() - index).toString());
            }

            return years;
        },
        downloadMonths () {
            const months = [];

            for (let index = 1; index < 13; index++) {
                months.push(index.toString().padStart(2, '0'));
            }

            return months;
        },
    },
    async beforeMount () {
        this.loading = true;
        await this.fetchCodaMandates();  // get coda mandates to check if client is eligible
        if (this.isClientEligible) {
            await this.loadRegistrations();
        }
        this.loading = false;
    },
    methods: {
        async loadRegistrations (params = { loading: true }) {
            Loader.start();
            this.loading = true;
            if (params.loading) {
                this.loading = true;
            }
            await this.queryCaroRegistrations();
            Loader.stop();
            this.loading = false;
        },
        async queryCaroRegistrations () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query CaroRegistrations($clientId: String) {
                            caroRegistrations(clientId:$clientId) {
                                id
                                clientReference
                                status
                                lastReceivedAt
                                invitation {
                                    clientEmail
                                    clientMobilePhoneNumber
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                this.registrations = data.caroRegistrations;
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
        },
        async fetchCodaMandates () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(clientId:$clientId, excludeMandatesWithoutBankAccounts:true) {
                                state
                                bank {
                                    isCaroSupported
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                this.codaMandates = data.codaMandates;
            } catch {
                notify.error(this.$t('err-unknown'));
            }
        },
        async addActivation () {
            const valid = await this.$refs.activationForm.validate();

            if (valid) {
                Loader.start();
                this.saving = true;
                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation OrderCaro($input: OrderCaroMandateInput!) {
                            orderCaroMandate(input: $input) {
                                errors {
                                    code,
                                    detail,
                                    source {
                                        pointer
                                    }
                                }
                            }
                        }`,
                        variables: {
                            input: {
                                clientMobilePhoneNumber: this.activation.clientMobilePhoneNumber,
                                clientId: this.clientV2.id,
                                clientLanguage: this.clientV2.language,
                                accountantLegalName: this.accountant.enterpriseName,
                                clientEmail: this.activation.clientEmail,
                                clientEnterpriseNumber: this.clientV2.enterpriseNumber,
                                clientReference: this.activation.clientReference,
                                clientLegalName: this.clientV2.representativeName,
                            },
                        },
                    });
                    if (data.orderCaroMandate.errors) {
                        validate.reportGQLFieldErrors(data.orderCaroMandate.errors, this.$refs.activationForm, activationErrorsMap);
                        validate.notifyGQLValidationErrors(data.orderCaroMandate.errors, activationErrorsMap);
                        Loader.stop();
                        this.saving = false;
                        return;
                    } else {
                        notify.success(this.$t('suc-css-invited'));
                    }
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                }
                Loader.stop();
                this.saving = false;
                this.loadRegistrations({loading: false});
                this.closeActivationModal();
            }
        },
        openActivationModal (registration = null) {
            if (registration) {
                this.activationType = EDIT;
                this.activation = {
                    clientReference: registration.clientReference,
                    clientEmail: registration.invitation.clientEmail,
                    clientMobilePhoneNumber: registration.invitation.clientMobilePhoneNumber,
                };
            } else {
                this.activationType = NEW;
                this.activation = {
                    clientEmail: this.clientV2.contactEmail,
                };
            }

            this.showActivationModal = true;
        },
        closeActivationModal () {
            this.showActivationModal = false;
        },
        openDownloadModal () {
            this.displayDownloadPopup = true;
        },
        closeDownloadModal () {
            this.displayDownloadPopup = false;
        },
        openDeactivationModal (registration) {
            this.registrationToDeactivate = registration;
            this.showDeactivationModal = true;
        },
        closeDeactivationModal () {
            this.registrationToDeactivate = null;
            this.showDeactivationModal = false;
        },

        async doClickDownloadFiles () {
            const valid = await this.$refs.downloadForm.validate();

            if (valid) {
                Loader.start();
                try {
                    const billingMonth = `${this.downloadDate.year}-${this.downloadDate.month}`;
                    const queryRes = await this.queryDownloadFilesUrl(billingMonth);

                    if (!queryRes.errors) {
                        window.open(queryRes.downloadUrl);
                    } else {
                        validate.notifyGQLValidationErrors(queryRes.errors, {
                            'NO_CREDIT_CARD_STATEMENTS_FOUND': 'err-ccs-no-deliveries-found',
                        });
                    }
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                }
                this.closeDownloadModal();
                Loader.stop();
            }
        },
        async queryDownloadFilesUrl (billingMonth) {
            const { data } = await this.$apollo.query({
                query: gql`query CaroFilesDownloadUrl($clientId: String, $billingMonth: String) {
                        caroFilesDownloadUrl(clientId:$clientId, billingMonth:$billingMonth) {
                            downloadUrl
                            errors { code }
                        }
                    }`,
                variables: {
                    clientId: this.clientV2.id,
                    billingMonth,
                },
            });
            return data.caroFilesDownloadUrl;
        },
        async doClickSuspend () {
            const valid = await this.$refs.deactivationForm.validate();

            if (this.registrationToDeactivate && valid) {
                Loader.start();
                await this.suspendCaro();
                Loader.stop();
            }
        },
        async suspendCaro () {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation SuspendCaro($input: CaroSuspensionInput) {
                        suspendCaro(input: $input) {
                            errors {
                                code,
                                detail
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.clientV2.id,
                            clientReference: this.registrationToDeactivate.clientReference,
                            reason: this.deactivationPayload.reason,
                            freeNote: this.deactivationPayload.freeNote,
                        },
                    },
                });
                notify.success(this.$t('suc-ccs-deactivation'));
                this.closeDeactivationModal();
                await this.loadRegistrations({ loading: false });
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
        },
        stateLabel (state) {
            return {
                'ACTIVE': 'ccs-status-lbl-active',
                'INACTIVE': 'ccs-status-lbl-inactive',
                'INVITATION_SENT': 'ccs-status-lbl-invitation-sent',
                'INVITATION_EXPIRED': 'ccs-status-lbl-invitation-expired',
                'PROBLEM': 'ccs-status-lbl-problem',
            }[state];
        },
        stateInfoLabel (state) {
            return {
                'ACTIVE': 'ccs-status-info-active',
                'INACTIVE': 'ccs-status-info-inactive',
                'INVITATION_SENT': 'ccs-status-info-invitation-sent',
                'INVITATION_EXPIRED': 'ccs-status-info-invitation-expired',
                'PROBLEM': 'ccs-status-info-problem',
            }[state];
        },
        markLastReceivedAt (lastReceivedAt) {
            let lastReceivedAtDate = new Date(lastReceivedAt);
            let compareDate = new Date();
            compareDate.setMonth(compareDate.getMonth() - 6);

            return lastReceivedAtDate < compareDate;
        },
        canEditRegistration (registration) {
            const statuses = ['INVITATION_SENT', 'INVITATION_EXPIRED', 'PROBLEM'];
            return statuses.includes(registration.status);
        },
        canDeactivate (registration) {
            const statuses = ['INACTIVE', 'PROBLEM'];
            return !statuses.includes(registration.status);
        },
    },
};
</script>
<template>
    <div class='client-subppage' v-if='!loading'>
        <content-box v-if='hasSubscribedCcs' :title='$t("h-caro-orders")'>
            <template #actions>
                <button
                    class='btn btn-alt mr-3'
                    @click='() => openDownloadModal()'
                    :disabled='hasNoRegistrationsWithLastReceivedAt'
                >
                    {{ $t('btn-ccs-download-files') }}
                </button>
                <button
                    class='btn btn-primary'
                    @click='() => openActivationModal()'
                    :disabled='!isClientOrderable'
                >
                    {{ $t('btn-ccs-new-activation') }}
                </button>
            </template>
            <div :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table'>
                    <colgroup>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                    </colgroup>
                    <thead>
                        <tr>
                            <th> {{ $t('th-client-reference') }} </th>
                            <th> {{ $t('th-ccs-email') }} </th>
                            <th> {{ $t('th-ccs-mobile-phone') }} </th>
                            <th> {{ $t('th-ccs-status') }} </th>
                            <th>
                                <span class='cb-state-label'>
                                    {{ $t('th-ccs-last-file-received-at') }}
                                </span>
                                <div class='cb-legend'>
                                    <i class='fa fa-info-circle'></i>
                                    <div class='cb-legend-body'>
                                        <div>
                                            {{ $t('ccs-last-file-received-at-info') }}
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th> {{ $t('th-actions') }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(registration, index) in registrations' :key='index' :id='"registration-" + registration.id'>
                            <td>{{ registration.clientReference }}</td>
                            <td class='truncate max-w-xs'>
                                {{ registration.invitation ? registration.invitation.clientEmail : '-' }}
                            </td>
                            <td><PhoneField :value='registration.invitation ? registration.invitation.clientMobilePhoneNumber : "-"' /></td>
                            <td>
                                <span class='cb-state-label'>
                                    {{ $t(stateLabel(registration.status)) }}
                                </span>
                                <div class='cb-legend'>
                                    <i class='fa fa-info-circle'></i>
                                    <div class='cb-legend-body'>
                                        <div v-html='$t(stateInfoLabel(registration.status))'></div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span v-if='registration.lastReceivedAt' :class='{"text-orange-300":markLastReceivedAt(registration.lastReceivedAt)}'>
                                    {{ registration.lastReceivedAt | dateFormat }}
                                </span>
                                <span v-else>-</span>
                            </td>
                            <td>
                                <button
                                    class='btn btn-alt btn-sm'
                                    @click='() => openActivationModal(registration)'
                                    v-if='canEditRegistration(registration)'
                                >
                                    {{ $t('btn-ccs-edit-activation') }}
                                </button>
                                <button
                                    class='btn btn-alt btn-sm'
                                    @click='() => openDeactivationModal(registration)'
                                    v-if='canDeactivate(registration)'
                                >
                                    {{ $t('btn-ccs-deactivation') }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </content-box>
        <content-box v-else>
            <div class='flex items-start mb-3'>
                <span
                    class='py-1 px-3 rounded-full inline-block whitespace-nowrap bg-opacity-20 bg-grey-300'
                >
                    {{ $t('caro-not-ordered') }}
                </span>
                <button
                    class='btn btn-primary ml-auto'
                    @click='() => openActivationModal()'
                    :disabled='!isClientOrderable'
                >
                    {{ $t('btn-order-credit-card-statement') }}
                </button>
            </div>
            <div class='panel panel-superwarning mb-0' v-if='!isClientOrderable'>
                <div class='panel-heading'>
                    <h3 class='panel-title'>
                        <i class='fa fa-info-circle'></i>
                    </h3>
                </div>
                <div class='panel-body'>
                    <p>
                        {{ $t('info-ccs-not-eligible') }} <br>
                        <strong>{{ $t('p-caro-supported-banks-info') }}</strong>
                    </p>
                </div>
            </div>
        </content-box>

        <modal :show='showActivationModal' id='order-modal' large>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='closeActivationModal'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title' v-if='activationType === NEW'>
                    {{ $t('btn-add-activation') }}
                </h4>
                <h4 class='modal-title' v-if='activationType === EDIT'>
                    {{ $t('ttl-ccs-popup-edit-activation', { client_reference: activation.clientReference }) }}
                </h4>
            </div>
            <div class='modal-body'>
                <ValidationObserver
                    ref='activationForm'
                    tag='div'
                >
                    <p>
                        {{ $t('p-caro-supported-banks-info') }}
                    </p>
                    <FormField
                        v-model='activation.clientReference'
                        :name='$t("lbl-ccs-client-reference")'
                        :disabled='activationType === EDIT'
                        type='ccsClientReference'
                        vid='clientReference'
                        placeholder='_ _ _ _ _ _ _ _ _ _'
                        edit
                        required
                        rules='not-null'
                        info='<img src="static/images/client-reference-info.png" width="280" />'
                        info-icon='fa fa-question-circle'
                    />
                    <p v-if='activationType === EDIT'>
                        {{ $t('p-caro-activation-edit') }}
                    </p>
                    <div>
                        <FormField
                            v-model='activation.clientEmail'
                            type='email'
                            :name='$t("lbl-ccs-client-email")'
                            vid='clientEmail'
                            :min='3'
                            :max='255'
                            :placeholder='$t("lbl-email")'
                            edit
                            required
                            rules='not-null'
                        />
                    </div>
                    <PhoneField
                        v-model='activation.clientMobilePhoneNumber'
                        vid='clientMobilePhoneNumber'
                        :name='$t("lbl-ccs-client-phone")'
                        :info='$t("info-ccs-client-phone")'
                        required
                        edit
                    />
                </ValidationObserver>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='closeActivationModal'>
                    {{ $t('btn-cancel') }}
                </button>
                <button
                    type='button'
                    class='btn btn-primary'
                    :disabled='saving'
                    @click.prevent='addActivation'
                >
                    <span v-if='activationType === NEW'>
                        {{ $t('btn-add-activation') }}
                    </span>
                    <span v-else>
                        {{ $t('btn-ccs-popup-edit-activation') }}
                    </span>
                </button>
            </div>
        </modal>

        <modal :show='displayDownloadPopup' id='download-files-modal'>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='closeDownloadModal'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('ttl-css-download-files', { client_code: clientV2.clientCode }) }}
                </h4>
            </div>
            <div class='modal-body'>
                <ValidationObserver ref='downloadForm' tag='div'>
                    <div class='row'>
                        <div class='col-md-4'>
                            <FormField
                                v-model='downloadDate.year'
                                type='select'
                                :placeholder='$t("lbl-year")'
                                edit
                                required
                            >
                                <option
                                    v-for='downloadYear in downloadYears'
                                    :value='downloadYear'
                                    :key='downloadYear'
                                >
                                    {{ downloadYear }}
                                </option>
                            </FormField>
                        </div>
                        <div class='col-md-4'>
                            <FormField
                                v-model='downloadDate.month'
                                type='select'
                                :placeholder='$t("lbl-month")'
                                edit
                                required
                            >
                                <option
                                    v-for='downloadMonth in downloadMonths'
                                    :value='downloadMonth'
                                    :key='downloadMonth'
                                >
                                    {{ downloadMonth }}
                                </option>
                            </FormField>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='closeDownloadModal'>
                    {{ $t('btn-cancel') }}
                </button>
                <button
                    type='button'
                    class='btn btn-primary'
                    :disabled='saving'
                    @click.prevent='doClickDownloadFiles'
                >
                    {{ $t('btn-ccs-download-files') }}
                </button>
            </div>
        </modal>

        <modal :show='showDeactivationModal' id='deactivation-modal'>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='closeDeactivationModal'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('ttl-css-deactivation', {client_reference: registrationToDeactivate ? registrationToDeactivate.clientReference : ''}) }}
                </h4>
            </div>
            <div class='modal-body'>
                <ValidationObserver ref='deactivationForm'
                                    tag='div'
                >
                    <p v-html='$t("p-ccs-deactivation")'></p>

                    <div class='deactivation-inputs mt-5'>
                        <FormField
                            v-model='deactivationPayload.reason'
                            vid='reason'
                            type='select'
                            :name='$t("lbl-css-deactivation-reason")'
                            :placeholder='$t("select-css-deactivation-reason")'
                            edit
                            required
                            narrow
                        >
                            <option value='CARD_STOPPED'>
                                {{ $t('lbl-css-reason-card-stopped') }}
                            </option>
                            <option value='CHANGED_BANK'>
                                {{ $t('lbl-css-reason-changed-bank') }}
                            </option>
                            <option value='TOO_EXPENSIVE'>
                                {{ $t('lbl-ccs-reason-too-expensive') }}
                            </option>
                            <option value='NOT_SATISFIED'>
                                {{ $t('lbl-css-reason-not-satisfied') }}
                            </option>
                            <option value='BUSINESS_STOPPED'>
                                {{ $t('lbl-css-reason-business-stopped') }}
                            </option>
                            <option value='CLIENT_NOT_PAYING'>
                                {{ $t('lbl-css-reason-client-not-paying') }}
                            </option>
                            <option value='NOT_DISCLOSED'>
                                {{ $t('lbl-css-reason-not-discloser') }}
                            </option>
                            <option value='CLIENT_MOVED_ACCOUNTANT'>
                                {{ $t('lbl-css-reason-client-moved-accountant') }}
                            </option>
                            <option value='OTHER'>
                                {{ $t('lbl-css-reason-other') }}
                            </option>
                        </FormField>
                        <FormField
                            v-model='deactivationPayload.freeNote'
                            vid='freeNote'
                            type='textarea'
                            :placeholder='$t("lbl-css-reason-other")'
                            v-if='deactivationPayload.reason === "OTHER"'
                            :max='255'
                            edit
                            required
                        />
                    </div>
                </ValidationObserver>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='closeDeactivationModal'>
                    {{ $t('btn-ccs-cancel-deactivation') }}
                </button>
                <button
                    type='button'
                    class='btn btn-primary'
                    :disabled='saving'
                    @click.prevent='doClickSuspend'
                >
                    {{ $t('btn-ccs-deactivation') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-subppage-header .btn+.btn {
        @apply ml-3;
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }
</style>
