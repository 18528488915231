<script>
    import ListView from '../components/ListView.js';
    import Clientlink from '../components/Clientlink.vue';
    import ContentBox from '../components/ContentBox.vue';
    import CustomTitle from '@/components/Title';

    export default {
        name: 'ccs-clients-list',
        mixins: [ListView],
        props: {
            currentFiduciary: {
                type: Object,
            },
        },
        components: {
            'client-link': Clientlink,
            ContentBox,
            CustomTitle,
        },
        data () {
            var data = this.listViewData({
                apiModel: 'bff/api/search/ccs-clients',
                defaultSortKey: 'client_code',
                summary: {
                    actives: 0,
                    invited: 0,
                    total: 0,
                },
                defaultFilters: {'state': 'active'},
            });

            this.configureSelectFilter(data, 'registration_status', [
                {
                    value: 'allstates',
                    label: 'opt-registrations-all',
                    filter: null,
                }, {
                    value: 'not_ordered',
                    label: 'ccs-status-lbl-not-ordered',
                    filter: {'registration_status': 'NOT_ORDERED'},
                }, {
                    value: 'invitation_sent',
                    label: 'ccs-status-lbl-invitation-sent',
                    filter: {'registration_status': 'INVITATION_SENT'},
                }, {
                    value: 'invitation_expired',
                    label: 'ccs-status-lbl-invitation-expired',
                    filter: {'registration_status': 'INVITATION_EXPIRED'},
                }, {
                    value: 'active',
                    label: 'ccs-status-lbl-active',
                    filter: {'registration_status': 'ACTIVE'},
                }, {
                    value: 'inactive',
                    label: 'ccs-status-lbl-inactive',
                    filter: {'registration_status': 'INACTIVE'},
                }, {
                    value: 'problem',
                    label: 'ccs-status-lbl-problem',
                    filter: {'registration_status': 'PROBLEM'},
                },

            ]);

            return data;
        },
        watch: {
            'currentFiduciary.id': function () {
                this.search();
            },
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dynamicSearchParams () {
                return {
                    'fiduciaryId': this.currentFiduciary.id,
                };
            },
        },
    };
</script>

<template>
    <div>
        <CustomTitle class='mt-12 mb-6'>
            {{ $t('h-ccs-clients-list') }}
        </CustomTitle>
        <content-box>
            <form @submit.prevent.stop class='form-inline clearfix cb-fidu-search'>
                <searchbox :list='list' />

                <selector :filter='true' :model='list.filters.registration_status' />

                <div class='cb-search-uppernav'>
                    <pagination :list='list'
                                :pagination='pagination'
                                :ctrls='getControls()'
                                size='small'
                    />
                </div>
            </form>

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='name' />
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='name' />
                            </th>
                            <th>
                                <span>{{ $t('th-ccs-banks') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-ccs-registration-status') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-actions') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records' :key='record.id' :id='"client-" + record.id'>
                            <td>
                                <client-link :client='record' />
                            </td>
                            <td>
                                {{ record.name }}
                            </td>
                            <td>
                                {{ record.banks.join(", ") }}
                            </td>
                            <td>
                                <span v-if='record.registrations_counters.total > 0'>
                                    {{ record.registrations_counters.active }} {{ $t('ccs-lbl-active') }} |
                                    {{ record.registrations_counters.invited }} {{ $t('ccs-lbl-invited') }} |
                                    <span
                                        :class='{"failed-color": record.registrations_counters.problem > 0}'
                                    >
                                        {{ record.registrations_counters.problem }} {{ $t('ccs-lbl-problem') }}
                                    </span> /
                                    {{ record.registrations_counters.total }} {{ $t('ccs-lbl-total') }}
                                </span>
                                <span v-else>
                                    {{ $t('ccs-status-lbl-not-ordered') }}
                                </span>
                            </td>
                            <td>
                                <router-link
                                    class='btn btn-xs btn-primary'
                                    :to='{name:"fidu-client-uid-credit-card-statement", params:{ uid: record.id }}'
                                >
                                    {{ $t('btn-ccs-manage') }}
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination :list='list'
                            :pagination='pagination'
                            :ctrls='getControls()'
                            scroll='scrollTop'
                            no-margin
                />
            </div>
        </content-box>
    </div>
</template>
