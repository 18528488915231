<script>
import Problems from '@/fiduciary/modules/Problems';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';

export default {
    name: 'dashboard',
    props: {
        currentFiduciary: {
            type: Object,
        },
    },
    components: {
        Problems,
        CustomTitle,
        ContentBox,
    },
};
</script>

<template>
    <div class='mt-6 mb-6 dashboard'>
        <div class='mb-10'>
            <CustomTitle>Dashboard</CustomTitle>
        </div>
        <div class='grid gap-6 2xl:grid-cols-3 grid-rows-3'>
            <div class='col-span-3 2xl:col-span-2'>
                <Problems :current-fiduciary='currentFiduciary' scrollable />
            </div>
            <div class='col-span-3 xl:col-span-1'>
                <content-box>
                    <CustomTitle :level='2' no-default-margins class='mb-3' :style-of='3'>
                        {{ $t('ttl-dashboard-feedback-module') }}
                    </CustomTitle>
                    <p>
                        {{ $t('p-dashboard-feedback-module') }}
                    </p>
                    <a
                        :href='$t("link-dashboard-feedback-module-href")'
                        target='_blank'
                    >
                        {{ $t('link-dashboard-feedback-module-content') }}
                    </a>
                </content-box>
            </div>
        </div>
    </div>
</template>
