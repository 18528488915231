<script>
    import Modal from '@/components/Modal';
    import FormField from '@/components/FormField';
    import Loader from '@/loader';
    import notify from '@/notify';
    import router from '@/router';
    import validate from '@/validate';
    import SettingsToggle from '@/components/SettingsToggle';
    import {gql} from '@apollo/client/core';
    import {VoilaMandateSubscribedStates} from '@/constants/voila2';
    import DatePicker from '@/components/DatePicker.vue';

    export default {
        name: 'client-platform-transfer',
        props: {
            relatedFiduciaries: Array,
            clientV2: Object,
        },
        components: {
            Modal,
            FormField,
            SettingsToggle,
            DatePicker,
        },
        data () {
            return {
                show: false,
                platformTransferData: {},
                saving: false,
                useContactEmailForVoila: true,
                extraClientData: {},
            };
        },
        apollo: {
            extraClientData: {
                query: gql`query ($clientId: String) {
                        currentVoilaMandate(clientId:$clientId) {
                             deliveryEmail,
                             status,
                        },
                        bankAccounts(clientId:$clientId) {
                            iban,
                        }
                    }`,
                variables () {
                    return { clientId: this.clientV2.id };
                },
                update: data => {
                    if (data.currentVoilaMandate && !VoilaMandateSubscribedStates.includes(data.currentVoilaMandate.status)) {
                        data.currentVoilaMandate = null;
                    }
                    return data;
                },
                skip: true,
            },
        },
        computed: {
            hasActiveVoilaService () {
                return !!this.extraClientData.currentVoilaMandate;
            },
            currentVoilaEmail () {
                return (
                    this.extraClientData.currentVoilaMandate &&
                    this.extraClientData.currentVoilaMandate.deliveryEmail
                ) ? this.extraClientData.currentVoilaMandate.deliveryEmail : null;
            },
        },
        watch: {
            useContactEmailForVoila: function (value) {
                if (value) {
                    this.platformTransferData.voilaDeliveryEmail = null;
                }
            },
            show: function (value) {
                if (value) {
                    this.platformTransferData = this.newPlatformTransferRequestData();
                    this.usePurchaseInvoiceEmailCheck();
                }
            },
        },
        methods: {
            newPlatformTransferRequestData () {
                return {
                    targetFiduciaryId: null,
                    clientId: this.clientV2.id,
                    enterpriseName: this.clientV2.enterpriseName,
                    enterpriseNumber: this.clientV2.enterpriseNumber,
                    hasBelgianVatNumber: this.clientV2.hasBelgianVatNumber,
                    representativeName: this.clientV2.representativeName,
                    representativeFunction: this.clientV2.representativeFunction,
                    address: this.clientV2.address,
                    address2: this.clientV2.address2,
                    zip: this.clientV2.zip,
                    city: this.clientV2.city,
                    clientCode: this.clientV2.clientCode,
                    language: this.clientV2.language,
                    contactName: this.clientV2.representativeName,
                    contactEmail: this.clientV2.contactEmail,
                    exactEmail: null,
                    codaDeliveryStartDate: null,
                    sendCodaAndSodaByMail: this.clientV2.sendCodaAndSodaByMail,
                    orderVoila: this.hasActiveVoilaService,
                    voilaDeliveryEmail: this.currentVoilaEmail,
                    bankAccounts: this.extraClientData.bankAccounts.map(ba => { return ba.iban; }),
                    socialOffices: [],
                };
            },
            usePurchaseInvoiceEmailCheck () {
                this.useContactEmailForVoila = !this.currentVoilaEmail;
            },
            async submitPlatformTransferRequest () {
                const valid = await this.$refs.createPlatformTransferForm.validate();

                if (!valid) {
                    return;
                }

                Loader.start();
                this.saving = true;

                let platformTransferDataCopy = this.platformTransferData;

                if (
                    this.platformTransferData.clientCode &&
                    this.platformTransferData.clientCode.length === 0
                ) {
                    platformTransferDataCopy.clientCode = this.clientV2.clientCode;
                }

                if (
                    this.platformTransferData.voilaDeliveryEmail &&
                    this.platformTransferData.voilaDeliveryEmail.length > 0
                ) {
                    platformTransferDataCopy.contactEmail = this.clientV2.contactEmail;
                }

                try {
                    const createMutation = await this.$apollo.mutate({
                        mutation: gql`mutation ($input: ClientTransferRequestCreateInput!) {
                            createClientTransferRequest(input: $input) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: {
                            input: platformTransferDataCopy,
                        },
                    });

                    const createResponse = createMutation.data.createClientTransferRequest;

                    if (createResponse.errors) {
                        const allErrors = createResponse.errors;

                        // Errors on fields of the form: errors with pointer to a field (ex: "/data/fieldName")
                        validate.reportGQLFieldErrors(allErrors, this.$refs.createPlatformTransferForm, {
                            'Already in use by a client of another fiduciary.': 'err-exact-email-not-unique',
                        });

                        // Global error: error with pointer to "/data"
                        const globalError = allErrors.find(error => {
                            return (
                                error.source && error.source.pointer && error.source.pointer === '/data'
                            );
                        });

                        if (globalError) {
                            if (globalError.code === 'alreadyExists' || globalError.code === 'alreadyExistsForFiduciary') {
                                // there is "alreadyExists" errors; display specific message
                                notify.error(this.$t('result-platform-transfer-request-failed-already-exists'));
                            } else {
                                // unexpected global error
                                notify.error(this.$t('result-platform-transfer-request-failed'));
                            }
                        }

                        // Bad client data errors
                        if (allErrors.find(error => error.code === 'blank' || error.code === 'null')) {
                            notify.error(this.$t('result-platform-transfer-request-failed-client-incomplete'));
                            this.close();
                        }
                    } else {
                        notify.success(this.$t('result-platform-transfer-request-success'));
                        this.close();
                        router.push('/fiduciary/clients');
                    }
                } catch (error) {
                    notify.error(this.$t('err-unknown'));
                }

                this.saving = false;
                Loader.stop();
            },
            voilaTogglesClick (activated) {
                if (!activated) {
                    this.platformTransferData.voilaDeliveryEmail = null;
                }
            },
            async open () {
                this.$apollo.queries.extraClientData.skip = false;
                await this.$apollo.queries.extraClientData.refetch();

                this.show = true;
            },
            close () {
                this.show = false;
            },
            nonAvailableDate (date) {
                const today = new Date();
                const twoYearsBefore = new Date();
                twoYearsBefore.setFullYear(today.getFullYear() - 2);
                return date >= today || date < twoYearsBefore;
            },
        },
    };
</script>
<template>
    <Modal :show='show' large>
        <div class='modal-header'>
            <button type='button' class='close' @click.prevent='close'>
                <span>&times;</span>
            </button>
            <h4 class='modal-title'>
                {{ $t('ttl-platform-transfer-request', {'client_name': clientV2.enterpriseName }) }}
            </h4>
        </div>
        <div class='modal-body'>
            <ValidationObserver
                ref='createPlatformTransferForm'
                tag='div'
                class='row box noborder'
            >
                <div class='col-md-12'>
                    <h4>{{ $t('ttl-select-software-to-transfer') }}</h4>

                    <FormField
                        v-model='platformTransferData.targetFiduciaryId'
                        vid='targetFiduciaryId'
                        type='select'
                        :placeholder='$t("ttl-select-software-to-transfer")'
                        edit
                        required
                    >
                        <option
                            v-for='relatedFiduciary in relatedFiduciaries'
                            :value='relatedFiduciary.id'
                        >
                            {{ relatedFiduciary.name }}
                        </option>
                    </FormField>

                    <h4>{{ $t('ttl-provide-client-data') }}</h4>

                    <FormField
                        v-model='platformTransferData.clientCode'
                        vid='clientCode'
                        :name='$t("lbl-client-code")'
                        :placeholder='$t("lbl-client-code")'
                        edit
                        :max='50'
                    />

                    <FormField
                        v-show='useContactEmailForVoila && hasActiveVoilaService'
                        v-model='platformTransferData.contactEmail'
                        vid='contactEmail'
                        type='email'
                        :name='$t("lbl-email")'
                        :placeholder='$t("lbl-email")'
                        edit
                    />

                    <FormField
                        v-model='platformTransferData.exactEmail'
                        vid='exactEmail'
                        type='email'
                        :name='$t("lbl-transfer-request-exact-email")'
                        :placeholder='$t("lbl-email")'
                        edit
                        nullable
                        :max='256'
                    />

                    <template v-if='hasActiveVoilaService'>
                        <h4>
                            {{ $t('ttl-purchase-invoice-preference') }}
                            <div class='cb-legend'>
                                <i class='fa fa-info-circle'></i>
                                <div class='cb-legend-body'>
                                    {{ $t('body-purchase-invoice-preference-info') }}
                                </div>
                            </div>
                        </h4>

                        <settings-toggle
                            v-model='platformTransferData.orderVoila'
                            @change='voilaTogglesClick'
                            class='form-group'
                        >
                            {{ $t('lbl-keep-pi-service') }}
                        </settings-toggle>

                        <div v-show='platformTransferData.orderVoila'>
                            <settings-toggle
                                v-model='useContactEmailForVoila'
                                @change='voilaTogglesClick'
                                class='form-group'
                            >
                                {{ $t('lbl-transfer-pi-email') }}
                            </settings-toggle>

                            <FormField
                                v-show='!useContactEmailForVoila'
                                v-model='platformTransferData.voilaDeliveryEmail'
                                vid='voilaDeliveryEmail'
                                type='email'
                                :name='$t("lbl-pi-email")'
                                :placeholder='$t("lbl-pi-email")'
                                :required='platformTransferData.orderVoila && !useContactEmailForVoila'
                                edit
                                :max='254'
                            />
                        </div>
                    </template>

                    <DatePicker
                        v-model='platformTransferData.codaDeliveryStartDate'
                        :name='$t("lbl-resend-coda-platform-transfer-title")'
                        :placeholder='$t("lbl-resend-coda-transfer-date-placeholder")'
                        :disabled-date='nonAvailableDate'
                        edit
                        class='mt-6'
                    >
                        <template #info>
                            {{ $t('lbl-resend-coda-platform-transfer-info') }}
                        </template>
                    </DatePicker>
                </div>
            </ValidationObserver>
        </div>
        <div class='modal-footer'>
            <button type='button' class='btn btn-default' @click.prevent='close'>
                {{ $t('btn-cancel') }}
            </button>
            <button type='button'
                    class='btn btn-primary'
                    :disabled='saving'
                    @click.prevent='submitPlatformTransferRequest'
            >
                {{ $t('btn-save') }}
            </button>
        </div>
    </Modal>
</template>
