<script>
    import SettingsToggle from './SettingsToggle';
    import Tooltip from '@/components/Tooltip';

    export default {
        name: 'form-field',
        props: {
            type: {
                type: String,
                default: 'text',
            },
            name: {
                type: String,
            },
            edit: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            value: {
                type: [String, Boolean],
            },
            info: {
                type: String,
            },
            infoIcon: {
                type: String,
                default: 'fa fa-info-circle',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            vid: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
            max: {
                type: Number,
            },
            maxValue: {
                type: Number,
            },
            minValue: {
                type: Number,
            },
            min: {
                type: Number,
            },
            length: {
                type: Number,
            },
            mode: {
                type: String,
                default: 'eager',
            },
            narrow: {
                type: Boolean,
                default: false,
            },
            nullable: {
                type: Boolean,
                default: false,
            },
            noMargin: {
                type: Boolean,
                default: false,
            },
            clean: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            SettingsToggle,
            Tooltip,
        },
        data () {
            return {
                content: this.value,
                hasFocus: false,
            };
        },
        computed: {
            ruleType () {
                // some of the types are not valid veevalidated types
                if (['text', 'boolean', 'select', 'password', 'textarea'].includes(this.type)) {
                    return '';
                } else if (this.type === 'number') {
                    return 'numeric';
                } else {
                    return this.type;
                }
            },
            ruleRequired () {
                return this.required ? 'required|' : '';
            },
            ruleMin () {
                return this.min ? `|min:${this.min}` : '';
            },
            ruleLength () {
                return this.length ? `|length:${this.length}` : '';
            },
            ruleMax () {
                return this.max ? `|max:${this.max}` : '';
            },
            ruleMinValue () {
                return this.minValue ? `|min_value:${this.minValue}` : '';
            },
            ruleMaxValue () {
                return this.maxValue ? `|max_value:${this.maxValue}` : '';
            },
            isSimpleInput () {
                const possibles = [
                    'text',
                    'email',
                    'phone',
                    'number',
                    'password',
                    'phone',
                    'ccsClientReference',
                    'integer',
                    'composedName',
                ];
                return possibles.includes(this.type);
            },
            isTextInput () {
                const possibles = [
                    'text',
                    'phone',
                    'ccsClientReference',
                    'integer',
                ];
                return possibles.includes(this.type);
            },
            isBoolean () {
                return this.type === 'boolean';
            },
            isSelect () {
                return this.type === 'select';
            },
            isTextarea () {
                return this.type === 'textarea';
            },
            isPassword () {
                return this.type === 'password';
            },
            computedContent () {
                return this.nullable && this.content.length <= 0 ? null : this.content;
            },
        },
        watch: {
            value (val) {
                this.content = val;
            },
        },
        mounted () {
            this.mounted = true;
        },
        methods: {
            handleInput (e) {
                this.content = e.target.value;
                if (this.$refs.field) {
                    this.$refs.field.reset();
                }
                this.$emit('input', this.computedContent);
            },
            handleToggleChange (value) {
                this.content = value;
                if (this.$refs.field) {
                    this.$refs.field.reset();
                }
                this.$emit('input', this.content);
            },
            blur (e) {
                this.hasFocus = false;
                this.content = this.clean ? this.cleanValue(e.target.value) : e.target.value;
                this.$emit('input', this.computedContent);
            },
            cleanValue (value) {
                return value.replace(/\s\s+/g, ' ').trim();
            },
        },
    };
</script>

<template>
    <div class='form-field' :class='{ narrow }'>
        <div v-if='isSimpleInput || isSelect || isTextarea'>
            <label v-if='name'>
                <span class='truncate'>
                    {{ name }}<span v-if='required && edit' class='required'>*</span>
                </span>
                <span v-if='info' class='ml-2 font-normal'>
                    <tooltip>
                        <template #trigger>
                            <i class='fa fa-info-circle'></i>
                        </template>
                        <template #content>
                            <div v-html='info'></div>
                        </template>
                    </tooltip>
                </span>
            </label>
        </div>
        <ValidationProvider v-if='edit && !disabled'
                            :rules='`${ruleRequired}${ruleType}${ruleMin}${ruleMax}${ruleMinValue}${ruleMaxValue}${ruleLength}`'
                            :mode='mode'
                            v-slot='{ errors, failedRules }'
                            :vid='vid'
                            ref='field'
                            tag='div'
                            :name='name'
        >
            <div :class='{"has-error": errors.length, "form-group": !noMargin}'>
                <div v-if='isSimpleInput' class='relative'>
                    <input :type='isTextInput ? "text" : type'
                           class='form-control'
                           v-model='content'
                           @focus='hasFocus = true'
                           @blur='blur'
                           @input='handleInput'
                           ref='input'
                           autocomplete='off'
                           :name='vid ? vid : name'
                           :placeholder='placeholder'
                           :disabled='disabled'
                    >

                    <slot></slot>
                </div>
                <div v-if='isTextarea'>
                    <textarea
                        class='form-control'
                        v-model='content'
                        @focus='hasFocus = true'
                        @blur='hasFocus = false'
                        @input='handleInput'
                        ref='input'
                        autocomplete='off'
                        :name='vid ? vid : name'
                        :placeholder='placeholder'
                    ></textarea>
                </div>
                <div v-if='isBoolean'>
                    <settings-toggle
                        :info='info'
                        v-model='content'
                        :disabled='disabled'
                        @change='handleToggleChange'
                    >
                        {{ name }}
                    </settings-toggle>

                    <slot></slot>
                </div>
                <div v-if='isSelect'>
                    <select class='form-control'
                            v-model='content'
                            :name='name'
                            @change='handleInput'
                    >
                        <option
                            :value='null'
                            selected
                            disabled
                            v-if='placeholder'
                        >
                            {{ placeholder }}
                        </option>
                        <slot></slot>
                    </select>
                </div>
                <div v-if='errors[0]' class='error-message'>
                    <div v-if='failedRules.required'>
                        {{ $t('err-required-constraint') }}
                    </div>
                    <div v-else-if='failedRules.email'>
                        {{ $t('err-email-not-valid') }}
                    </div>
                    <div v-else>
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </ValidationProvider>
        <div class='cb-form-value' v-else>
            <div v-if='isBoolean'>
                <settings-toggle
                    :info='info'
                    v-model='content'
                    disabled
                >
                    {{ name }}
                </settings-toggle>

                <slot></slot>
            </div>
            <div v-else-if='$slots["customValue"]'>
                <slot name='customValue'></slot>
            </div>
            <div v-else class='flex flex-col'>
                <span class='inline-block mr-3 truncate w-full'>{{ value }}</span>
                <!-- we most likely do not display slots here when input in a select
                     because it contains the options -->
                <div class='inline-block' v-if='$slots.default'>
                    <slot v-if='!isSelect'></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>
.form-field {
    &.narrow {
        width: 320px;
    }
}

.relative {
    position: relative;
}

.required {
    color: $primary-color;
}
</style>
