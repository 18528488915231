<script>
    import DigitalSignatureButton from '@/components/DigitalSignatureButton';
    import CodaDownload from '@/components/Codadownload';
    import CodaTeletransmissionDownload from '@/components/CodaTeletransmissionDownload';
    import Tooltip from '@/components/Tooltip';

    export default {
        name: 'coda-mandate-buttons',
        props: {
            mandate: {
                type: Object,
                required: true,
            },
            cssClass: {
                type: String,
                default: '',
            },
        },
        components: {
            DigitalSignatureButton,
            CodaDownload,
            CodaTeletransmissionDownload,
            Tooltip,
        },
        data () {
            return {
                digitalSignatureRefreshCount: 0,
                digitalSignatureIssue: false,
            };
        },
        computed: {
            hasDownloadState () {
                // if changing this logic, also change it in the bff's graphql.js addSignHerePackageToMandate function
                return this.mandate.bank.name && ['available-online', 'prepared', 'sent-client', 'problem'].includes(this.mandate.state);
            },
            nonArchivedBankAccounts () {
                return this.mandate.bankAccounts.filter((ba) => ba.state !== 'archived');
            },
            bankMandatesAreAlreadyActive () {
                // we consider that the bank mandates are already active if
                // all non-archived bank accounts are in the 'draft-active-bank-mandate' flow-state
                return this.nonArchivedBankAccountsAllHaveFlowState('draft-active-bank-mandate');
            },
        },
        watch: {
            mandate: {
                handler () {
                    this.refreshCodaMandatesForSignhere();
                },
                deep: true,
            },
        },
        beforeMount () {
            // if mandate bank is signhere, and there is not signhere package or package url
            // then throw a refeshCodaMandates event every 3 seconds, up to 10 times
            this.refreshCodaMandatesForSignhere();
        },
        methods: {
            nonArchivedBankAccountsAllHaveFlowState (flowState) {
                return this.nonArchivedBankAccounts.every((ba) => ba.flowState === flowState);
            },
            refreshCodaMandatesForSignhere () {
                if (
                    this.mandate.bank.isSignhereSupported &&
                    !(
                        this.mandate.signHerePackage &&
                        this.mandate.signHerePackage.packageSignUrl
                    )
                ) {
                    if (this.digitalSignatureRefreshCount < 10) {
                        setTimeout(() => {
                            this.$emit('refreshCodaMandates');
                            this.digitalSignatureRefreshCount++;
                            this.refreshCodaMandatesForSignhere();
                        }, 3000);
                    } else {
                        this.digitalSignatureIssue = true;
                    }
                }
            },
        },
    };
</script>
<template>
    <div v-if='hasDownloadState'>
        <template v-if='bankMandatesAreAlreadyActive'>
            <!-- Bank mandate state is active: always display DL button for TT mandate -->
            <CodaTeletransmissionDownload :mandate='mandate' />
        </template>
        <template v-else>
            <!-- in correct order
                if there is a signhere package, then display signhere button (if no url yet, display message to refresh page)
                if bank is twikey AND there is a twikeyUrl, then display twikey button
                else if no disableSendPdfMandate, then display coda download button
            -->
            <template v-if='mandate.bank.isSignhereSupported'>
                <template v-if='mandate.signHerePackage && mandate.signHerePackage.packageSignUrl'>
                    <DigitalSignatureButton
                        :url='mandate.signHerePackage.packageSignUrl'
                        v-if='mandate.signHerePackage.packageStatus === "pending"'
                    />
                </template>
                <p v-else>
                    <span v-if='digitalSignatureIssue && mandate.state === "problem"' class='text-red-300'>
                        {{ $t('lbl-digital-signature-issue-problem') }}
                    </span>
                    <span v-else-if='digitalSignatureIssue' class='text-red-300'>
                        <tooltip left>
                            <template #trigger>
                                <i class='fa fa-warning'></i>
                                {{ $t('lbl-digital-signature-not-available') }}
                            </template>
                            <template #content>
                                {{ $t('lbl-digital-signature-issue') }}
                            </template>
                        </tooltip>
                    </span>
                    <span v-else class='text-grey-500'>
                        <span>
                            {{ $t('lbl-wait-digital-signature-url') }}...
                        </span>
                        <i class='fa fa-circle-o-notch fa-spin'></i>
                    </span>
                </p>
            </template>
            <template v-else-if='mandate.bank.isTwikeySupported && mandate.twikeyUrl'>
                <DigitalSignatureButton :url='mandate.twikeyUrl' />
            </template>
            <template v-else-if='!mandate.bank.disableSendPdfMandate'>
                <CodaDownload :mandate='mandate' :class='cssClass' />
            </template>
        </template>
    </div>
</template>
